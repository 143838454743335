@import '../../styles/base.scss';

:local {

  .header {
    margin-top: 60px;
    margin-bottom: 60px;
    @media screen and (min-width: $medium-content-width) {
      margin-bottom: 20px;
    }
  }

  .title {
    text-align: center;
    font-size: 24px;
  }

  .logoWrapper {
    margin: auto;
    width: calc(100vw - 30px);
    max-width: 40rem;
    display: flex;
    padding: 0 auto;
    gap: 48px;
    justify-content: left;
    flex-direction: column;
    align-items: flex-start;
    
    @media screen and (min-width: $medium-content-width) {
      max-width: 922px;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: center;
    }
  }

  .berlinLogoWrapper {
    margin-bottom: 48px;
  }

  .bundLogoWrapper {
    margin-bottom: 30px;
    flex-direction: column;
    
    @media screen and (min-width: $medium-content-width) {
      flex-direction: row;
    }
  }

  .contentBlock {
    max-width: $medium-content-width;
    margin: 60px auto;
    display: flex;
    flex-direction: column;
  }
}
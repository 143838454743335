@import '../../../styles/base.scss';

:local {
  .tile {
    height: auto;
    width: auto;
    border-radius: $border-radius-standard;
    background-color: $white;
    padding: 24px 24px 34px;
    @include box-shadow;
    
    @media screen and (min-width: $break-tablet-min) {
      width: 100%;
    }

    &.dark {
      color: $grey-90;
    }
  }
}
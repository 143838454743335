html {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

body {
  margin: 0;
  font-family: Clan;
  -moz-osx-font-smoothing: grayscale;
  color: #404040;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@import '../../styles/base.scss';
:local {
  .iconWrapper {
    width: 36px;
    height: 36px;
    background-color: $grey-10;
    padding: 15px;
    border-radius: 100px;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

@import '../../styles/base.scss';

:local {
  .tileWrapper {
    height: 200vh;
    width: calc(100% - 20px);
    margin: 0 auto;
    
    @media screen and (min-width: $break-tablet-min) {
      margin-left: 30px;
      width: 400px;
      height: 150vh;
    }
    
    @media screen and (min-width: $break-desktop-min) {
      height: 200vh;
    }
  }
  .title {
    font-size: 24px;
    line-height: 32px;
    margin: 13px 0 19px;
    font-weight: $bold-text;
  }
  .subtitle {
    font-size: $font-size-l;
    margin: 15px 0 15px;
    font-weight: normal;
  }
  .text {
    font-size: $font-size-m;
    line-height: 24px;
    margin-bottom: 0px;  
    margin-top: 10px;
  }
  .linkWrapper {
    display: flex;
    flex-wrap: wrap;

    a {
      margin-bottom: 10px;
    }
  }
  .label {
    font-size: $font-size-m;
    letter-spacing: $letter-spacing-m;
    color: $grey-70;
  }
  .id {
    color: $grey-70;
  }
}
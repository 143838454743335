@import '../../styles/base.scss';

:local {
  .wrapper {
    height: auto;
    width: auto;
    max-width: $medium-content-width;
    margin: 10px auto 60px;
    @include flex();
  }

  .description {
    max-width: 327px;
  }

  .contentWrapper {
    max-width: $large-content-width;
    margin: 90px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 57px 0;

    img {
      position: relative;
      width: calc(100% - 28px);
    }
    
    @media screen and (min-width: $large-content-width) {
      flex-direction: row;
      img {
        width: calc(50% - 28px);
      }
    }
    
  }

}
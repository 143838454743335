@import '../../styles/base.scss';

:local {
  .footer {
    position: absolute;
    left: 0;
    width: 100vw;

    .logos {
      display: flex;
      padding: 0 20px 1rem;
      width: calc(100vw - 40px);
      background-color: #f5f5f5;
      flex-direction: column;
      justify-content: flex-start;
      margin: 0 auto;
      gap: 2rem;
  
      @media screen and (min-width: $break-tablet-min) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin: 0 auto;
        width: calc(100vw - 20%);
        padding: 0 10% 1rem;
        height: 80px;
        gap: 4rem;
      }
    }

    .label {
      font-size: $font-size-s;
      min-height: 20px;
      display: block;
    }
    .logoWrapper {
      max-width: 35rem;
      img {
        height: 40px;
      }

      @media screen and (min-width: $break-tablet-min) {
        display: block;
        max-width: auto;
        img {
          height: 40px;
        }
      }
    }
      
    .bottom {
      padding: 14px 20px;
      width: calc(100vw - 40px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      background-color: $white;
      color: #404040;
      line-height: 24px;
      
      .left {
        display: flex;
        align-items: flex-start;
      }

      .items {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 16px 38px;
        padding: 32px 0 0;
        
        @media screen and (min-width: $xtra-large-content-width) {
          padding: 0;
          width: 65%;
        }
      }

      .copyright {
        font-size: 14px;
      }

      .link {
        color: #404040;
        font-size: 14px;
      }

      @media screen and (min-width: $xtra-large-content-width) {
        flex-direction: row;
        padding: 15px 10% 28px;
        width: calc(100vw - 20%);
      }
    }
  }
}
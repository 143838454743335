@import '../../styles/base.scss';

:local {
  .wrapper {
    height: auto;
    width: auto;
    max-width: $medium-content-width;
    margin: $section-margin;
    @include flex();
  }
}
@import '../../styles/base.scss';

:local {
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
    color: $grey-90;
    font-weight: normal;
    font-size: $font-size-s;
    margin-left: 12px;
    min-height: 30px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: $font-size-xs;
    transition: $transition-fast;

    &:hover {
      opacity: .6;
    }
  }

  .iconWrapper {
    width: 18px;
    height: 18px;
  }

  .label {
    line-height: 8px;
    font-size: 8px;
  }

  .divider {
    height: 15px;
    width: 1px;
    background-color: $grey-90;
    opacity: 1;
  }
}